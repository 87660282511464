import "./Contact.scss";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="contact-me">
      <h1 className="mb-5">Contact Me</h1>
      <div className="contact-info">
        <br />
        <a href="mailto:canoltasgin@gmail.com">
          <FaEnvelope className="mr-2" />
          Email
        </a>
        <br />
        <a
          href="https://www.linkedin.com/in/canoltasgin/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin className="mr-2" />
          LinkedIn
        </a>
        <br />
        <a href="https://github.com/CanolTasgin" target="_blank">
          <FaGithub className="mr-2" />
          GitHub
        </a>
      </div>
    </div>
  );
};

export default Contact;
