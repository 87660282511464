import "./Extracurricular.scss";
import React from "react";
import { Row, Col } from "react-bootstrap";

const Extracurricular = () => {
  return (
    <div>
      <Row className="extracurricular">
        <Col lg={12}>
          <div className="title">
            <h4>Co-founder & President</h4>
            <span className="dates">October 2022 - June 2023</span>
          </div>
          <div className="club">
            <a target="_blank" href="//humbertech.org">
              Humber College Tech Society
            </a>
            , Toronto ON
          </div>
          <div>
            <ul>
              <li>
                Structured and set up the club regulations according to school
                regulations.
              </li>
              <li>
                Formed teams and managed department and board meetings to
                organize events.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="extracurricular mt-5">
        <Col lg={12}>
          <div className="title">
            <h4>President</h4>
            <span className="dates">April 2020 - June 2021</span>
          </div>
          <div className="club">
            <a
              target="_blank"
              href="https://www.instagram.com/css.sabanciuniv/"
            >
              Sabanci University Computer Club
            </a>
          </div>
          <div>
            <ul>
              <li>
                Joined team efforts of different club departments to help event
                organizations.
              </li>
              <li>Managed board and general club meetings regularly.</li>
              <li>
                Maintained relationships with companies, alumni, and faculty for
                the club
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Extracurricular;
