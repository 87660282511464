import "./Header.scss";
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import canol from "../static/images/CanolTasgin.png";

const Header = () => {
  return (
    <Container>
      <Row className="align-items-center">
        <Col xs={12} md={6}>
          <img
            src={canol}
            alt="Software developer coding on a multiple monitors"
            className="img-fluid header-img"
          />
        </Col>
        <Col xs={12} md={6} className="header-text text-center">
          <h1 className="landing-title">Canol Tasgin</h1>
          <p className="landing-text">
            Computer Engineer, Full-Stack Developer, Cloud Engineer
          </p>
          <Button href="/projects" variant="primary">
            View Projects
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
export default Header;
