import "./ProjectDetails.scss";
import React from "react";
import { useParams } from "react-router-dom";
import ImageCarousel from "./ImageCarousel";

const ProjectDetails = (props) => {
  const projects = require("../static/projects.json");
  // images

  let { projPath } = useParams();
  const project = projects.find((proj) => proj.path === projPath);

  return (
    <div className="page-content">
      <ImageCarousel images={project.images} path={project.path} />
      <h1>{project.name}</h1>
      <div className="project-content">
        <p>{project.description}</p>
        <div className="tech-stack">
          <h4>Technologies</h4>
          <ul>
            {project.technologies.map((tech, i) => (
              <li key={i}>{tech}</li>
            ))}
          </ul>
        </div>
        <h4>Details</h4>
        <div
          dangerouslySetInnerHTML={{ __html: project.detailed_description }}
        />
      </div>
    </div>
  );
};
export default ProjectDetails;
