import "./Certifications.scss";
import React from "react";

const Certifications = () => {
  return (
    <div>
      <div className="certificate">
        <h4>AWS Certified Solutions Architect – Associate</h4>
        <span className="dates">April 02, 2023</span>
      </div>
      <div className="certificate">
        <h4>AWS Certified Cloud Practitioner</h4>
        <span className="dates">July 28, 2022</span>
      </div>
      <div className="certificate">
        <h4>AWS Cloud Technical Essentials</h4>
        <span className="dates">July 03, 2022</span>
      </div>
      <div className="certificate">
        <h4>Hackerrank Rest API (Intermediate)</h4>
        <span className="dates">Feb 23, 2021</span>
      </div>
    </div>
  );
};

export default Certifications;
