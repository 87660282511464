import "./Education.scss";
import React from "react";
import { Row, Col } from "react-bootstrap";

const Education = () => {
  return (
    <div>
      {/* <Row className="education">
        <Col lg={12}>
          <div className="title">
            <h4>Master of Professional Studies in Informatics</h4>
            <span className="dates">Jan 24 - present</span>
          </div>
          <div className="school">Northeastern University, Toronto ON</div>
          <p>
            <u>Expected Graduation</u>: January 2025
          </p>
        </Col>
      </Row> */}
      <Row className="education">
        <Col lg={12}>
          <div className="title">
            <h4>Postgraduate Certificate, Cloud Computing</h4>
            <span className="dates">Sep 2022 - April 2023</span>
          </div>
          <div className="school">Humber College, Toronto ON</div>
          <div className="key-courses">
            <h5>Key Courses</h5>
            <ul>
              <li>Spring Boot</li>
              <li>AWS EC2, Cloudwatch, Lambda, DynamoDB, SNS</li>
              <li>Gitlab</li>
              <li>Jira</li>
              <li>Confluence</li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="education mt-5">
        <Col lg={12}>
          <div className="title">
            <h4>Bachelor of Sciences, Computer Science & Engineering</h4>
            <span className="dates">Sep 2015 - Aug 2021</span>
          </div>
          <div className="school">Sabanci University</div>
          <div className="key-courses">
            <h5>Key Courses</h5>
            <ul>
              <li>Advanced Programming</li>
              <li>Database Systems</li>
              <li>Algorithms</li>
              <li>Data Structures</li>
              <li>Software Engineering</li>
              <li>Cryptography</li>
              <li>Machine Learning</li>
              <li>Machine Learning</li>
              <li>Operating Systems</li>
              <li>Graduation Project</li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Education;
