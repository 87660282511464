import "./FooterBar.scss";
import React from "react";
import { Container, Nav } from "react-bootstrap";

const FooterBar = () => {
  return (
    <footer className="p-4 text-center">
      <Container>
        <Nav className="justify-content-center">
          <Nav.Item>
            <a href="/">Home</a>
          </Nav.Item>
          <Nav.Item>
            <a href="/projects">Projects</a>
          </Nav.Item>
          <Nav.Item>
            <a href="/resume">Resume</a>
          </Nav.Item>
          <Nav.Item>
            <a href="/contact">Contact</a>
          </Nav.Item>
        </Nav>
      </Container>
    </footer>
  );
};

export default FooterBar;
