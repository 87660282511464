import "./ResumeTabs.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CoreQual from "./resume/CoreQual";
import WorkEx from "./resume/WorkEx";
import Education from "./resume/Education";
import Certifications from "./resume/Certifications";
import ProjectList from "./resume/ProjectList";
import Extracurricular from "./resume/Extracurricular";

const ResumeTabs = () => {
  return (
    <div className="tab-contents">
      <Tabs
        defaultActiveKey="core-qualifications"
        id="uncontrolled-tab-example"
        className="mb-3 tabs"
      >
        <Tab
          eventKey="core-qualifications"
          title="Core Qualifications"
          className="tab"
        >
          <CoreQual />
        </Tab>
        <Tab eventKey="work-experience" title="Work Experience" className="tab">
          <WorkEx />
        </Tab>
        <Tab eventKey="education" title="Education" className="tab">
          <Education />
        </Tab>
        <Tab eventKey="certifications" title="Certifications" className="tab">
          <Certifications />
        </Tab>
        <Tab eventKey="projects" title="Projects" className="tab">
          <ProjectList />
        </Tab>
        <Tab
          eventKey="extracurricular"
          title="Extracurricular Activities"
          className="tab"
        >
          <Extracurricular />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ResumeTabs;
