import "./WorkEx.scss";
import React from "react";
import { Row, Col } from "react-bootstrap";

const WorkEx = () => {
  return (
    <div>
      <Row className="work-experience mt-5">
        <Col lg={12}>
          <div className="title">
            <h4>Senior Programmer Analyst</h4>
            <span className="dates">July 2023 - present</span>
          </div>
          <div className="company">
            Ontario Ministry of Children, Community and Social Services
          </div>
          {/* <p>
            • Engaged in full-stack development for the SADA project, utilizing
            Spring Boot for backend development and Angular for frontend
            architecture, ensuring a seamless user experience and robust system
            functionality. • Spearheaded the development of a GPT-based chatbot
            for the SADA project. Research focused on optimizing user
            interaction and providing accurate responses while addressing
            security concerns. Reported findings to managers and the director. •
            Took the initiative to build an auto-filler application for the SADA
            application, which is being used by most of the team (devs,testers,
            POs) and saves time for almost 20 people daily. (Python, Selenium) •
            Maintaining a separate Spring project that synchronizes applicant
            information between IRCC and province ministries. • Leading team of
            11 on Ontario Public Services AI Hackathon. • Contributed to the
            team's adoption of Agile methodologies, actively participating in
            daily scrum meetings, iteration planning meetings, and
            retrospectives to continuously improve processes and outcomes. •
            Supported QA and DevOps tasks when needed. • Assisted new team
            members through environment setup and project onboarding to
            facilitate their integration and productivity.
          </p> */}
          <ul>
            <li>
              Engaged in full-stack development for the SADA project, utilizing
              Spring Boot for backend development and Angular for frontend
              architecture, ensuring a seamless user experience and robust
              system functionality.
            </li>
            <li>
              Spearheaded the development of a GPT-based chatbot for the SADA
              project. Research focused on optimizing user interaction and
              providing accurate responses while addressing security concerns.
              Reported findings to managers and the director.
            </li>
            <li>
              Took the initiative to build an auto-filler application for the
              SADA application, which is being used by most of the team (devs,
              testers, POs) and saves time for almost 20 people daily. (Python,
              Selenium)
            </li>
            <li>
              Maintaining a separate Spring project that synchronizes applicant
              information between IRCC and province ministries.
            </li>
            <li>
              Leading team of 11 on Ontario Public Services AI Hackathon to
              improve speed and accuracy of an existing on production
              application.
            </li>
            <li>
              Contributed to the team's adoption of Agile methodologies,
              actively participating in daily scrum meetings, iteration planning
              meetings, and retrospectives to continuously improve processes and
              outcomes.
            </li>
            <li>Supported QA and DevOps tasks when needed.</li>
            <li>
              Assisted new team members through environment setup and project
              onboarding to facilitate their integration and productivity.
            </li>
          </ul>
          <div className="tech-stack">
            <h5>Technologies</h5>
            <ul>
              <li>Spring Boot</li>
              <li>Azure</li>
              <li>Angular</li>
              <li>Azure DevOps</li>
              <li>Docker</li>
              <li>PCF (GoCloud)</li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="work-experience mt-5">
        <Col lg={12}>
          <div className="title">
            <h4>Web Developer - Coop</h4>
            <span className="dates">May 2023 - July 2023</span>
          </div>
          <div className="company">
            Ontario Ministry of Children, Community and Social Services
          </div>
          <p>
            Gaining valuable experience in web development, primarily focusing
            on Java Spring Boot and working with front-end frameworks like React
            and AngularJS. Although my responsibilities continue to evolve, I am
            already applying my knowledge and skills in these key areas to
            contribute effectively to my team's projects.
          </p>
          <div className="tech-stack">
            <h5>Technologies</h5>
            <ul>
              <li>Spring Boot</li>
              <li>Azure</li>
              <li>Angular</li>
              <li>Azure DevOps</li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="work-experience mt-5">
        <Col lg={12}>
          <div className="title">
            <h4>Backend Developer</h4>
            <span className="dates">Feb 2022 - Aug 2022</span>
          </div>
          <div className="company">Accenture</div>
          <p>
            Worked as a backend developer in an international team of engineers
            for UK-based streaming platform ALL4 (
            <a target="_blank" href="https://www.channel4.com/">
              channel4.com
            </a>
            ). Made bug fixes and improvements on microservices implemented with
            Spring Boot and AWS Lambda. Wrote and improved automation tests for
            Spring Boot-based services. Practiced scaled agile (SAFe). Supported
            QA and Release tasks when needed.
          </p>
          <div className="tech-stack">
            <h5>Technologies</h5>
            <ul>
              <li>Spring Boot</li>
              <li>AWS EC2, Cloudwatch, Lambda, DynamoDB, SNS</li>
              <li>Gitlab</li>
              <li>Jira</li>
              <li>Confluence</li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="work-experience mt-5">
        <Col lg={12}>
          <div className="title">
            <h4>Full Stack Developer</h4>
            <span className="dates">June 2021 - Feb 2022</span>
          </div>
          <div className="company">Ronwell Digital</div>
          <p>
            Joined development efforts of a cross-platform e-commerce website as
            a full-stack developer. Implemented and maintained REST and GRAPHQl
            APIs on AWS. Used both SQL and NoSQL databases. Created and improved
            the admin panel for the website with ReactJs. Created and maintained
            the confluence documentation for the APIs
          </p>
          <div className="tech-stack">
            <h5>Technologies</h5>
            <ul>
              <li>
                AWS Lambda (Python), Appsync, DynamoDB, Amazon RDS (PostgreSQL),
                ElasticSearch, Cognito
              </li>
              <li>React</li>
              <li>Github</li>
              <li>Jira</li>
              <li>Confluence</li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="work-experience mt-5">
        <Col lg={12}>
          <div className="title">
            <h4>Frontend Developer</h4>
            <span className="dates">March 2021 - June 2021</span>
          </div>
          <div className="company">Ronwell Digital</div>
          <p>
            Worked as a front-end developer for the e-commerce application's
            mobile application and admin panel.
          </p>
          <div className="tech-stack">
            <h5>Technologies</h5>
            <ul>
              <li>React</li>
              <li>React Native</li>
              <li>Github</li>
              <li>Jira</li>
              <li>Confluence</li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WorkEx;
