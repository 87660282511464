import "./ProjectList.scss";
import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";

const ProjectList = () => {
  const projects = require("../../static/projects.json");

  const [selectedTech, setSelectedTech] = useState([]);

  // function to toggle a technology in the selected technologies list
  const toggleTech = (tech) => {
    if (selectedTech.map((t) => t.toLowerCase()).includes(tech.toLowerCase())) {
      setSelectedTech(
        selectedTech.filter((t) => t.toLowerCase() !== tech.toLowerCase())
      );
    } else {
      setSelectedTech([...selectedTech, tech.toLowerCase()]);
    }
  };

  // filter the projects based on the selected technologies
  const filteredProjects = projects.filter((project) => {
    return selectedTech.every((tech) =>
      project.technologies.map((t) => t.toLowerCase()).includes(tech)
    );
  });

  return (
    <div className="projects-list">
      {/* display the filters as buttons */}
      <div className="filters">
        {projects
          .reduce((techs, project) => {
            // get the unique technologies used in all the projects
            techs.push(
              ...project.technologies.filter(
                (tech) =>
                  !techs
                    .map((t) => t.toLowerCase())
                    .includes(tech.toLowerCase())
              )
            );
            return techs;
          }, [])
          .map((tech) => (
            <Button
              key={tech}
              onClick={() => toggleTech(tech)}
              active={selectedTech
                .map((t) => t.toLowerCase())
                .includes(tech.toLowerCase())}
            >
              {tech.charAt(0).toUpperCase() + tech.slice(1)}
            </Button>
          ))}
      </div>
      {/* display the filtered projects */}
      {filteredProjects.map((project) => (
        <div className="project-names" key={project.id}>
          <h4>
            <a href={`/project/${project.path}`}>{project.name}</a>
          </h4>
          <p>{project.description}</p>
        </div>
      ))}
    </div>
  );
};

export default ProjectList;
