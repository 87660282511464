import "./ProjectCard.scss";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
// import DiscForum from "../static/images/DiscussionForumLanding.jpg";
import { Link } from "react-router-dom";
import placeholder from "../static/images/placeholder.png";

const ProjectCard = (props) => {
  const [landingImg, setLandingImg] = useState();

  useEffect(() => {
    let landingImg;
    if (props.path) {
      try {
        landingImg = require(`../static/images/${props.path}/landing.jpg`);
      } catch (e) {
        landingImg = require(`../static/images/${props.path}/landing.png`);
      }
    }
    setLandingImg(landingImg);
  }, [props.path]);

  return (
    <div>
      <Card>
        {/* if landingImg is not empty, show landing img */}
        {landingImg ? (
          <Card.Img src={landingImg} />
        ) : (
          <Card.Img src={placeholder} />
        )}
        <Card.Body>
          <Card.Title>{props.name}</Card.Title>
          <Card.Text>{props.desc}</Card.Text>
        </Card.Body>
        <Card.Body>
          <Link to={`/project/${props.path}`}>
            <Button variant="primary">Project Details</Button>
          </Link>
          {props.published ? (
            // <Button variant="primary" target="_blank" href={props.link}>
            //   Project Link
            // </Button>
            // If link is not empty, show project link button, if not, don't show anything
            props.link !== "" ? (
              <Button variant="primary" target="_blank" href={props.link}>
                Project Link
              </Button>
            ) : (
              <></>
            )
          ) : (
            props.path !== "graduation" && (
              <Button variant="primary" disabled>
                In Dev
              </Button>
            )
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProjectCard;
