import "./Projects.scss";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../components/ProjectCard";

const Projects = () => {
  const projects = require("../static/projects.json");

  return (
    <div>
      <Container className="card-section">
        <Row>
          {projects.map((item) => {
            return (
              <Col xs={12} lg={6} xl={4} className="card-column col-left">
                <ProjectCard
                  key={item.id}
                  name={item.name}
                  desc={item.description}
                  published={item.published}
                  link={item.link}
                  path={item.path}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Projects;
