import React from "react";
import "./Layout.scss";
import NavigationBar from "./components/layout/NavigationBar";
import FooterBar from "./components/layout/FooterBar";
import { Outlet } from "react-router-dom";

const Layout = (props) => {
  return (
    <div>
      <NavigationBar />
      <div className="main-content">
        <Outlet />
      </div>
      <FooterBar />
    </div>
  );
};

export default Layout;
