import "./Resume.scss";
import React from "react";
import ResumeTabs from "../components/ResumeTabs";
import { BiDownload } from "react-icons/bi";
import resume from "../static/CanolTasgin_Resume.pdf";

const Resume = () => {
  return (
    <div>
      <div className="title">
        <h1>Resume</h1>
        <a
          href="https://humbercc-events-bucket.s3.eu-central-1.amazonaws.com/CanolTasgin_Resume.pdf"
          download
        >
          <BiDownload />
        </a>
      </div>
      <ResumeTabs />
    </div>
  );
};

export default Resume;
