import "./CoreQual.scss";
import React from "react";

const CoreQual = () => {
  return (
    <div className="core-qual-content">
      <p>
        Full-stack development expertise, with a strong background in both
        front-end and back-end technologies.
      </p>
      <p>
        Collaborated with international engineering teams to develop key
        projects including the Social Assistance Digital Application (SADA),
        backend services for a UK-based video-on-demand service, and a
        cross-platform e-commerce website.
      </p>
      {/* <p>
        Active Master of Professional Studies in Informatics student at
        Northeastern University, demonstrating advanced knowledge of current
        informatics trends and practices.
      </p> */}
      <p>
        Proven leadership and team-building skills, with experience in guiding
        new team members and leading student clubs in a presidential capacity.
      </p>
      <div className="tech-skills">
        <h4>Programming Languages</h4>
        <ul>
          <li>Java</li>
          <li>Python</li>
          <li>Javascript</li>
          <li>SQL</li>
        </ul>

        <h4>Frameworks</h4>
        <ul>
          <li>Spring Boot</li>
          <li>Angular</li>
          <li>Django</li>
          <li>Flask</li>
          <li>ReactJs</li>
          <li>NextJS</li>
        </ul>

        <h4>Cloud Services & Other Tools</h4>
        <ul>
          <li>AWS</li>
          <li>Azure</li>
          <li>Github Actions</li>
          <li>Jenkins</li>
          <li>Azure DevOps</li>
          <li>Jira</li>
          <li>Confluence</li>
        </ul>
      </div>
    </div>
  );
};

export default CoreQual;
