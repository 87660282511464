import "./ImageCarousel.scss";
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import PlaceHolder from "../static/images/placeholder.png";

const ImageCarousel = (props) => {
  return (
    <div>
      <Carousel>
        {props.images.length === 0 ? (
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={PlaceHolder}
              alt="Placeholder"
            />
            <Carousel.Caption>
              <h3>Placeholder</h3>
              <p>Waiting for images to load</p>
            </Carousel.Caption>
          </Carousel.Item>
        ) : (
          props.images.map((image, i) => (
            <Carousel.Item key={i}>
              <img
                className="d-block w-100"
                src={require(`../static/images/${props.path}/${image.image_path}`)}
                alt={image.image_name}
              />
              <Carousel.Caption>
                <h3>{image.image_name}</h3>
                <p>{image.image_description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))
        )}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
